/* load family font 'Pluto Sans' : Black, Bold, Medium, Regular, Light from src/assets/fonts, files .eot,.svg,.ttf,.woff,woff3 */
@font-face {
  font-family: 'Pluto Sans';
  src: url('./assets/fonts/PlutoSansBlack.woff2') format('woff2'),
    url('./assets/fonts/PlutoSansBlack.woff') format('woff'),
    url('./assets/fonts/PlutoSansBlack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pluto Sans';
  src: url('./assets/fonts/PlutoSansBold.woff2') format('woff2'),
    url('./assets/fonts/PlutoSansBold.woff') format('woff'), url('./assets/fonts/PlutoSansBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pluto Sans';
  src: url('./assets/fonts/PlutoSansMedium.woff2') format('woff2'),
    url('./assets/fonts/PlutoSansMedium.woff') format('woff'),
    url('./assets/fonts/PlutoSansMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pluto Sans';
  src: url('./assets/fonts/PlutoSansRegular.woff2') format('woff2'),
    url('./assets/fonts/PlutoSansRegular.woff') format('woff'),
    url('./assets/fonts/PlutoSansRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pluto Sans';
  src: url('./assets/fonts/PlutoSansLight.woff2') format('woff2'),
    url('./assets/fonts/PlutoSansLight.woff') format('woff'),
    url('./assets/fonts/PlutoSansLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Pluto Sans', sans-serif;
  background: #ffffff;
}

/* loader */

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.452);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* rotate image in Y animation */
.loader img {
  position: absolute;
  width: 200px;
  animation: rotateY 2s linear infinite;
}

@keyframes rotateY {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

/* sidebar y content */

.sidebar {
  position: fixed;
  background: url(./assets/sidebar-back.png) no-repeat right center/cover;
  top: 0;
  left: 0;
  width: 180px;
  height: 100%;
  z-index: 100;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-right: 20px;
}

.sidebar-content__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid #e9b94a;
}

.sidebar-content__menu {
  list-style: none;
  padding: 10px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  margin: 0;
  height: 100%;
}

.sidebar-content__menu li {
  cursor: pointer;
  color: white;
  font-size: 0.8em;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 0.9em;
  transition: all 0.3s ease;
}

.sidebar_item__icon {
  display: block;
  width: 45px;
}

.sidebar_item__icon path {
  fill: #ffffff;
  transition: all 0.3s ease;
}

.sidebar-content__menu li:hover {
  color: #e9b94a;
}

.sidebar-content__menu li:hover .sidebar_item__icon path {
  fill: #e9b94a;
}

.sidebar_redes {
  color: white;
  border-top: 3px solid #e9b94a;
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  flex-direction: column;
  margin-bottom: 20px;
}

.sidebar_redes__rowitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0.6em;
}

.sidebar_redes__rowitem a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4380bf;
  font-size: 0.9em;
  text-decoration: none;
  background: white;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  transition: all 0.3s ease;
}

.sidebar_redes__rowitem a:hover {
  background: #e9b94a;
  color: white;
}

.sidebar-content__lang {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100px;
  margin-top: 10px;
}

.sidebar-content__flag {
  cursor: pointer;
}

/* back sections */
.home {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(./assets/back-home.jpg) no-repeat center center/cover;
}

/* CSS effects */
.logo-white {
  width: 130px;
  max-width: 100%;
  filter: grayscale(100%) brightness(300%);
  transition: all 0.3s ease;
  cursor: pointer;
}

/* 360 view */
.map-menu {
  width: 60px;
  position: fixed;
  right: 30px;
  top: 5%;
}

.map-menu__item {
  background: white;
  color: black;
  width: 50px;
  border-radius: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8em;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.map-menu__item:hover,
.map-menu__item.active {
  background: #4380bf;
  color: white;
}

.map-menu__item path {
  fill: black;
  transition: all 0.3s ease;
}

.map-menu__item:hover path,
.map-menu__item.active path {
  background: #4380bf;
  fill: white;
}

.icon360 {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* float buttons */
.button-float {
  position: fixed;
  z-index: 999;
  right: 20px;
  width: 80px;
  height: 80px;
  cursor: pointer;
  animation: buzz 2s infinite;
}

@keyframes buzz {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.button-float img {
  width: 100%;
}

.button-float.top {
  top: 25%;
}

.button-float.bottom {
  bottom: 20%;
}

/* Vive */
.vive {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(./assets/back-vive.jpg) no-repeat center center/cover;
}

.vive-container {
  width: 350px;
  max-width: 100%;
  background: #035da3e6;
  padding: 20px 30px;
  border-radius: 25px;
  color: white;
  right: 60px;
  top: 30px;
  position: absolute;
}

.vive-title {
  text-align: center;
  font-size: 1em;
}

.vive-content {
  font-size: 0.8em;
  line-height: 1.3em;
  font-weight: 300;
  text-align: justify;
}

.vive-icons {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 30px;
}

.vive-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vive-icon .number {
  font-size: 1.8em;
}

.vive-icon .text {
  text-transform: uppercase;
  font-size: 0.7em;
}

.vive-icon img {
  width: 60px;
}

.vive-logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Contacto */

.contacto {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(./assets/back-contacto.jpg) no-repeat center center/cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contacto-info_item {
  display: flex;
  color: white;
  align-items: center;
  margin-bottom: 10px;
}

.contacto-info_item img {
  width: 25px;
  margin-right: 10px;
}

.contacto-info_item a {
  color: white;
  text-decoration: none;
}

.contacto-bottom__left {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid white;
}

.contacto-bottom___left img {
  width: 280px;
  max-width: 80%;
  margin-right: 10px;
}

/* form */
.form-title_top {
  color: #e9b94a;
  font-size: 1.5em;
  text-align: center;
  line-height: 1em;
}

.form-price {
  font-size: 5em;
  font-weight: 500;
  color: #e9b94a;
  text-align: center;
}

.form-title_bottom {
  color: #e9b94a;
  font-size: 2.5em;
  text-align: center;
  line-height: 1em;
}

.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.form-label {
  color: white;
  font-size: 1.5em;
  position: relative;
}

.form-label::before {
  content: '';
  position: absolute;
  top: 10px;
  left: -10px;
  width: 20px; /* Adjust the width as per your requirement */
  height: 100%;
  border-top: 1px solid #fff; /* Adjust the line thickness and color as per your requirement */
  border-top-right-radius: 15px; /* Adjust the radius as per your requirement */
  transform: rotate(-90deg);
}

.form-label::after {
  content: '';
  position: absolute;
  top: 10px;
  right: -10px;
  width: 20px; /* Adjust the width as per your requirement */
  height: 100%;
  border-top: 1px solid #fff; /* Adjust the line thickness and color as per your requirement */
  border-top-left-radius: 15px; /* Adjust the radius as per your requirement */
  transform: rotate(90deg);
}

.form-inputs {
  margin-top: 10px;
  width: 400px;
  max-width: 100%;
}

input[type='text'],
input[type='email'] {
  width: 370px;
  max-width: 100%;
  border: none;
  outline: none;
  background: #4380bf;
  color: white;
  height: 35px;
  border-radius: 25px;
  font-family: 'Pluto Sans', sans-serif;
  padding: 2px 15px;
  font-weight: 300;
}

.form-input {
  margin-top: 5px;
}

/* add placeholder color:white */
input[type='text']::-webkit-input-placeholder,
input[type='email']::-webkit-input-placeholder {
  color: white;
  font-family: 'Pluto Sans', sans-serif;
  font-weight: 300;
}

input[type='text']::-moz-placeholder,
input[type='email']::-moz-placeholder {
  color: white;
  font-family: 'Pluto Sans', sans-serif;
  font-weight: 300;
}

input[type='text']:-ms-input-placeholder,
input[type='email']:-ms-input-placeholder {
  color: white;
  font-family: 'Pluto Sans', sans-serif;
  font-weight: 300;
}

input[type='text']:-moz-placeholder,
input[type='email']:-moz-placeholder {
  color: white;
  font-family: 'Pluto Sans', sans-serif;
  font-weight: 300;
}

.button-primary {
  background: white;
  color: #3874ba;
  border-radius: 25px;
  width: 130px;
  outline: none;
  box-shadow: 1px 1px 5px #000;
  transition: all 0.3s ease-in-out;
  margin-top: 20px;
  padding: 10px 12px;
  border: none;
  font-family: 'Pluto Sans', sans-serif;
  font-weight: 500;
  font-size: 1em;
}

.button-primary:hover {
  background: #3874ba;
  color: white;
}

.form-container {
  padding: 40px 20px;
}

.contacto-top,
.contacto-bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 180px;
}

.contacto-icons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: relative;
  /* z-index: 1; */
}

.contacto-icons__item_icon {
  background: white;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.contacto-icons::before {
  content: '';
  background: white;
  height: 1px;
  width: 80%;
  position: absolute;
  top: 35px;
  left: 10%;
  z-index: 0;
}

.contacto-icons__item {
  z-index: 1;
}

.contacto-icons__item_number {
  color: white;
  font-size: 1.2em;
  margin-top: 15px;
  text-align: center;
}

.contacto-icons__item_text {
  color: white;
  font-size: 0.8em;
  margin-top: 15px;
  text-align: center;
  width: 100px;
}

.contacto-map__iframe {
  border: 0;
  width: 450px;
  height: 450px;
  max-width: '100%';
  border-radius: 25px;
}

.contacto-map {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-financieros {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-financieros__item {
  text-align: center;
  color: white;
  font-size: 0.7em;
}

.contact-financieros__item img {
  width: 150px;
  background: white;
  border-radius: 25px;
  margin: 0px 5px;
  padding: 5px;
  margin-top: 3px;
}

/* zonas sociales */

.zonas-sociales__menu {
  background: #144385;
  width: calc(100% - 160px);
  height: 70px;
  position: absolute;
  bottom: 0;
  left: 130px;
  padding-left: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.zonas-sociales {
  width: 100%;
  background-size: cover;
  height: 100%;
  background-position: center bottom;
  background-color: #144385;
}

.zonas-sociales__menu_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 14);
  height: auto;
  margin-top: -35px;
  cursor: pointer;
}

.zonas-sociales__menu_icon img {
  width: 60%;
}

.zonas-sociales__background {
  width: calc(100% - 180px);
  margin-left: 180px;
  height: calc(100% - 70px);
}

.zonas-sociales__background img {
  height: 100% !important;
  width: auto !important;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 5px #0000004e;
}

/* modal */
.modal {
  display: block;
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(255 255, 255, 0.852);
}

.modal-close-button {
  font-size: 1em;
  cursor: pointer;
}

.modal__content__image {
  width: 80%;
  display: block;
  margin: auto;
  height: 100%;
}

.modal__content__image img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: auto;
  margin: auto;
  display: block;
}

/* flex */

.d-flex {
  display: flex;
}
.flex-center {
  justify-content: center;
  align-items: center;
}

#planta-seccion {
  width: calc(100% - 8%);
  margin-left: 8%;
}

#planta-seccion > img {
  width: 100%;
  height: 100%;
}

#etapa-uno {
  width: calc((100% - 12.4%) / 2);
  background-color: #4380bf;
  right: 0;
  z-index: 2;
}

#etapa-dos {
  width: calc((100% - 12%) / 2);
  background-color: #306391;
  padding-left: 3.7%;
  left: 8%;
}

.etapas {
  position: absolute;
  height: 100%;
  z-index: 1;
  top: 0;
  z-index: 2;
}

.etapas-empty {
  position: absolute;
  background-color: transparent !important;
  height: 100%;
  z-index: 1 !important;
  top: 0;
  cursor: pointer;
}

button.btn-plantas {
  border: none;
  outline: none;
  position: absolute;
  top: 10%;
  color: white;
  font-family: 'Pluto Sans';
  font-weight: 400;
  font-size: 22px;
  padding: 5px 15px;
  transition: all ease 0.4s;
  animation: buzz 2s infinite;
  cursor: pointer;
}

button.btn-plantas::before {
  /* add image */
  content: '';
  position: absolute;
  top: 25px;
  right: -20px;
  width: 40px;
  height: 40px;
  background: url(./assets/click-icon.png) no-repeat center center;
  background-size: 100% 100%;
}

button.btn-plantas.dos {
  right: 34%;
  background: rgb(48, 112, 179);
  background: -moz-linear-gradient(270deg, rgba(48, 112, 179, 1) 0%, rgba(0, 49, 132, 1) 100%);
  background: -webkit-linear-gradient(270deg, rgba(48, 112, 179, 1) 0%, rgba(0, 49, 132, 1) 100%);
  background: linear-gradient(270deg, rgba(48, 112, 179, 1) 0%, rgba(0, 49, 132, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3070b3",endColorstr="#003184",GradientType=1);
}

button.btn-plantas.uno {
  left: 42%;
  background: rgb(48, 112, 179);
  background: -moz-linear-gradient(90deg, rgba(48, 112, 179, 1) 0%, rgba(0, 49, 132, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(48, 112, 179, 1) 0%, rgba(0, 49, 132, 1) 100%);
  background: linear-gradient(90deg, rgba(48, 112, 179, 1) 0%, rgba(0, 49, 132, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3070b3",endColorstr="#003184",GradientType=1);
}

.etapa-content {
  margin: 40px;
}

.btn-back img {
  width: 40px;
  cursor: pointer;
}

.etapa-title {
  color: white;
  font-size: 2em;
  margin-top: 30px;
  font-weight: 100;
}

.etapa-text {
  color: white;
  display: flex;
}

.etapa-text ul {
  padding: 20px;
  font-size: 0.9em;
  width: 220px;
}

/* tipologia */
#section-tipologias {
  margin-left: 150px;
  width: calc(100% - 150px);
}

#section-tipologias h1 {
  color: #606060;
  text-align: center;
  margin: 0;
  padding-top: 30px;
}

.tab_content__price {
  color: #4380bf;
  font-size: 1.7em;
  font-weight: 500;
}

.tab_content__separator {
  width: 166px;
  background: #606060;
  height: 1px;
  margin: 7px 0px;
}

.tab_content__areas {
  display: flex;
  justify-content: space-between;
  width: 220px;
}

.tab_content__area__title {
  font-size: 0.3em;
  font-weight: bold;
  color: #606060;
}

.tab_content__area__value {
  color: #606060;
  font-size: 1em;
  font-weight: 500;
}

.tab_content__amenidades {
  font-size: 0.7em;
  color: #606060;
  font-weight: 500;
}

.tab_content__extensiones {
  font-size: 0.7em;
  color: #606060;
  font-weight: 500;
}

.tab_content__amenities__and__extensions {
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin-bottom: 20px;
}

.tab_content__plain {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tab_content__plain > img {
  width: 400px;
  max-width: 100%;
  cursor: pointer;
}

.tab_content__plain__zoom__icon img {
  width: 100%;
  cursor: pointer;
}

.tab_content__plain__zoom__icon {
  position: absolute;
  width: 30px;
  right: -20px;
  top: 45%;
}

.tipologias-subtitle {
  margin: 0px 5px;
  text-align: center;
  color: #606060;
  font-size: 1em;
}

/* tabs */

.tabs {
  width: 90%;
  margin: 0 auto;
  padding: 0 20px;
  margin-top: 40px;
}

.tabs__list {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
}

.tabs__list__item {
  padding: 10px 20px;
  background-color: #e9b94a;
  color: white;
  border-bottom: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tabs__list__item:first-child {
  border-radius: 25px 0px 0px 25px;
}

.tabs__list__item:last-child {
  border-radius: 0px 25px 25px 0px;
}

.tabs__list__item.active {
  background-color: #4380bf;
}

.tabs__content__item {
  display: none;
  padding: 20px;
}

.tabs__content__item.active {
  display: block;
}

.tab-content {
  display: flex;
}

.tab-content__image img {
  width: 100%;
}

.tab-content__text {
  flex: 1;
  padding: 0 20px;
}

.fullwidth {
  width: 80%;
}

.landscape {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  z-index: 99999;
}

.landscape img {
  width: 150px;
  margin-bottom: 15px;
  animation: rotate 3s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(250deg);
  }

  100% {
    transform: rotate(135deg);
  }
}

.popup {
  height: 90% !important;
  margin-top: 5% !important;
  width: auto !important;
  box-shadow: 0 0 15px;
}

@media (max-height: 690px) {
  .sidebar-content__menu li {
    font-size: 0.5em;
  }

  .sidebar_item__icon {
    width: 25px;
  }
}

@media (max-height: 700px) {
  .sidebar-content__menu li {
    font-size: 0.8em;
  }

  .sidebar_item__icon {
    width: 30px;
  }
}

@media (max-height: 750px) {
  .sidebar-content__menu li {
    font-size: 0.8em;
  }

  .sidebar_item__icon {
    width: 33px;
  }
}

@media (max-height: 800px) {
  .sidebar-content__menu li {
    font-size: 0.8em;
  }

  .sidebar_item__icon {
    width: 35px;
  }

  .contacto-map__iframe {
    border: 0;
    width: 400px;
    height: 300px;
    max-width: '100%';
    border-radius: 25px;
  }
}

@media (max-height: 450px) {
  .sidebar {
    width: 100px;
  }

  .sidebar-content {
    width: 100%;
  }

  .logo-white {
    width: 70px;
  }

  .sidebar-content__menu li {
    font-size: 8px;
    width: 80px;
  }

  .sidebar_item__icon {
    width: 20px;
  }

  .sidebar_redes {
    padding: 0px;
    width: 60px;
  }

  .sidebar_redes__rowitem {
    margin-top: 5px;
  }

  .sidebar_redes__rowitem a {
    width: 15px;
    height: 15px;
    font-size: 10px;
  }

  .vive-container {
    width: 60%;
    padding: 12px;
  }

  .vive-title {
    font-size: 8px;
  }

  .vive-content {
    font-size: 10px;
  }

  .vive-icons {
    margin-top: 5px;
  }

  .vive-icon img {
    width: 45px;
  }

  .vive-icon .number {
    font-size: 18px;
  }

  .vive-icon .text {
    font-size: 8px;
  }

  .zonas-sociales__menu {
    width: calc(100% - 90px);
    left: 60px;
    height: 40px;
  }

  .zonas-sociales__menu_icon {
    margin-top: -30px;
  }

  .zonas-sociales__menu_icon img {
    width: 80%;
  }

  .button-float {
    width: 50px;
    right: 0;
  }

  .button-float.bottom {
    bottom: 20%;
  }

  .btn-back img {
    width: 20px;
  }

  .etapa-title {
    margin-top: 5px;
    font-size: 14px;
  }

  .etapa-text ul {
    font-size: 8px;
    padding: 5px;
    width: 110px;
  }

  img.fullwidth {
    width: 100%;
  }

  #section-tipologias {
    margin-left: 100px;
    width: calc(100% - 100px);
  }

  #section-tipologias h1 {
    font-size: 14px;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 5px;
  }

  .tipologias-subtitle {
    font-size: 12px;
    margin-right: auto;
    margin-left: auto;
    width: 80%;
  }

  .tabs {
    margin-top: 10px;
  }

  li.tabs__list__item {
    font-size: 12px;
    padding: 5px 10px;
  }

  .tabs__content__item {
    padding: 5px;
  }

  .tab_content__price {
    font-size: 18px;
  }

  .tab_content__separator {
    width: 100px;
  }

  .tab_content__areas {
    width: 152px;
  }

  .tab_content__amenities__and__extensions {
    width: 170px;
    margin-bottom: 0;
  }

  .tab_content__amenidades {
    font-size: 9px;
  }

  .tab_content__extensiones {
    font-size: 8px;
  }

  .tab_content__area__title {
    font-size: 8px;
  }

  .tab_content__area__value {
    font-size: 12px;
  }

  .tab_content__plain {
    margin-top: 0px;
  }

  .tab-content__text {
    padding: 0 8px;
  }

  ul.tabs__list {
    margin-bottom: 5px;
  }

  .form-container {
    padding: 0px 10px;
  }

  .form-title_top {
    font-size: 14px;
  }

  .form-price {
    font-size: 40px;
    font-weight: 400;
  }

  .form-title_bottom {
    font-size: 20px;
  }

  .form {
    margin-top: 5px;
  }

  .form-label {
    font-size: 12px;
  }

  .form-inputs {
    width: 200px;
  }

  input[type='text'],
  input[type='email'] {
    width: 175px;
    padding: 2px 10px;
    height: 25px;
  }

  button.button-primary {
    margin-top: 5px;
    padding: 5px 6px;
  }

  iframe.contacto-map__iframe {
    width: 250px;
    height: 150px;
  }

  .contacto-icons {
    margin-top: 15px;
  }

  .contacto-icons__item_icon {
    width: 35px;
    height: 35px;
  }

  .contacto-icons__item_icon img {
    width: 90%;
  }

  .contacto-icons__item_number {
    font-size: 12px;
    margin-top: 5px;
  }

  .contacto-icons__item_text {
    font-size: 10px;
    margin-top: 5px;
    width: 70px;
  }

  .contacto-icons::before {
    top: 17px;
  }

  .contacto-top,
  .contacto-bottom {
    padding-left: 100px;
  }

  .contacto-bottom__left img {
    width: 150px;
  }

  .contacto-info_item {
    font-size: 8px;
    margin-bottom: 0px;
  }

  .contact-financieros__item img {
    width: 60px;
  }

  .contact-financieros__item {
    font-size: 6px;
  }

  .contacto-info_item img {
    width: 15px;
    margin-right: 5px;
  }

  .contacto-top__left {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
